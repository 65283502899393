import {
  PlasmicComponent,
  extractPlasmicQueryData,
  ComponentRenderData,
  PlasmicRootProvider,
} from '@plasmicapp/loader-nextjs';
import type { GetStaticPaths, GetStaticProps } from 'next';
import Error from 'next/error';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nextConfig from '../next-i18next.config';
import { PLASMIC } from '../plasmic-init';

export default function PlasmicLoaderPage(props: {
  plasmicData?: ComponentRenderData;
  queryCache?: Record<string, unknown>;
  path: string;
}) {
  const { plasmicData, queryCache } = props;
  const router = useRouter();

  if (!plasmicData || plasmicData.entryCompMetas.length === 0) {
    return <Error statusCode={404} />;
  }

  const pageMeta = plasmicData.entryCompMetas[0];

  return (
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      pageParams={pageMeta.params}
      pageQuery={router.query}
      globalVariants={[]}
    >
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { catchall } = context.params ?? {};
  const locale = context.locale || 'en-US';
  const plasmicPath =
    typeof catchall === 'string' ? catchall : Array.isArray(catchall) ? `/${catchall.join('/')}` : '/';

  const plasmicData = await PLASMIC.maybeFetchComponentData(plasmicPath);

  if (!plasmicData) {
    // non-Plasmic catch-all
    return { props: {} };
  }
  const pageMeta = plasmicData.entryCompMetas[0];
  // Cache the necessary data fetched for the page
  const queryCache = await extractPlasmicQueryData(
    <PlasmicRootProvider loader={PLASMIC} prefetchedData={plasmicData} pageParams={pageMeta.params}>
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>,
  );
  const translations = await serverSideTranslations(
    locale,
    [
      'common',
      'landing',
      'email',
      'summary',
      'plans',
      'checkout',
      'upsells',
      'upsellMeditation',
      'upsellBundle',
      'success',
      'articles',
    ],
    i18nextConfig,
  );

  return {
    props: {
      path: plasmicPath,
      locale,
      plasmicData,
      queryCache,
      ...translations,
    },
    revalidate: process.env.NODE_ENV === 'development' ? 10 : undefined,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const pageModules = await PLASMIC.fetchPages();
  const filteredPages = pageModules.filter(({ path }) => !/^\/o.*$/.test(path));

  return {
    paths: filteredPages.map((mod) => ({
      params: {
        catchall: mod.path.substring(1).split('/'),
      },
    })),
    fallback: 'blocking',
  };
};
