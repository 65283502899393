import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text, Radio } from '@chakra-ui/react';
import { FC, ReactNode, useState } from 'react';
import PaypalLogo from '@shared/assets/general/paypal_logo.png';
import CardsLogo from '@shared/assets/general/accept_cards.png';

export const PaymentsAccordion: FC<{
  className?: string;
  defaultActive?: 'card' | 'paypal';
  card?: ReactNode;
  paypal?: ReactNode;
}> = ({ className, defaultActive = 'paypal', card, paypal }) => {
  const [active, setActive] = useState(defaultActive);

  const PaypalItem = (
    <AccordionItem key='paypal_item' marginBottom='1rem' backgroundColor='white' borderRadius='0.5rem'>
      <AccordionButton
        gap='0.5rem'
        padding='1rem'
        backgroundColor='rgba(0, 0, 0, 0.02)'
        borderRadius='0.5rem'
        border='1px solid #dadad0'
        _expanded={{ borderRadius: '0.5rem 0.5rem 0 0', borderBottom: 'none' }}
        onClick={() => setActive('paypal')}
        cursor='pointer'
      >
        <Radio bg={active === 'paypal' ? '#2563eb' : 'white'} isChecked={active === 'paypal'} />

        <Text color='black' fontSize='14px' fontWeight={600}>
          PayPal
        </Text>
        <Flex flex='1' justifyContent='right'>
          <Box as='img' src={PaypalLogo.src} height='16px' alt='' />
        </Flex>
      </AccordionButton>
      <AccordionPanel padding='1rem' border='1px solid #dadad0' borderRadius='0 0 0.5rem 0.5rem'>
        {paypal}
      </AccordionPanel>
    </AccordionItem>
  );
  const CardItem = (
    <AccordionItem key='card_item' marginBottom='1rem' backgroundColor='white' borderRadius='0.5rem'>
      <AccordionButton
        gap='0.5rem'
        padding='1rem'
        backgroundColor='rgba(0, 0, 0, 0.02)'
        borderRadius='0.5rem'
        border='1px solid #dadad0'
        _expanded={{ borderRadius: '0.5rem 0.5rem 0 0', borderBottom: 'none' }}
        onClick={() => setActive('card')}
        cursor='pointer'
      >
        <Radio bg={active === 'card' ? '#2563eb' : 'white'} isChecked={active === 'card'} />
        <Text color='system.black' fontSize='14px' fontWeight={600}>
          Credit Card
        </Text>
        <Flex flex='1' justifyContent='right'>
          <Box as='img' src={CardsLogo.src} height='16px' alt='' />
        </Flex>
      </AccordionButton>
      <AccordionPanel padding='1rem' border='1px solid #dadad0' borderRadius='0 0 0.5rem 0.5rem'>
        {card}
      </AccordionPanel>
    </AccordionItem>
  );

  return (
    <Box id='payments_component' maxWidth='30rem' margin='auto' className={className}>
      <Accordion defaultIndex={[0]}>
        {defaultActive === 'paypal' ? [PaypalItem, CardItem] : [CardItem, PaypalItem]}
      </Accordion>
    </Box>
  );
};

PaymentsAccordion.displayName = 'PaymentsAccordion';
