export const colors = {
  primary: '#3FA134',
  secondary: '#233E20',
  system: {
    blue: '#3633DD',
    pink: '#EB009B',
    yellow: '#FFD442',

    // general
    white: '#FFFFFF',
    black: '#050517',
    error: '#f47777',
    success: '#05952E',
  },
  body: '#233E20',
  heading: '#233E20',
  buttonPrimary: '#fff',
  buttonSecondary: '#FFD442',
  inputBg: '#EEEFEB',
  inputBorder: 'rgba(0, 0, 0, 0.2)',
  questionSubheading: 'rgba(0, 0, 0, 0.8)',
  question: {
    option: {
      border: 'rgba(0, 0, 0, 0.1)',
      borderActive: '#233E20',
      bgHover: '#ededed',
      bgActive: '#DAFDD7',
      bg: '#EEEFEB',
      text: '#233E20',
      textActive: '#233E20',
      iconFill: '#233E20',
    },
  },
  info: {
    border: '#dfcc8e',
    bg: '#FFF8E1',
  },
  toggle: {
    border: '#D1D2CD',
    bg: '#f4f4f4',
    bgActive: '#3FA134',
  },
};
