import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { TransText } from '~/components/TransText';
import { config } from '~/config';
import { useAppLead } from '~/hooks/useAppLead';
import { useProducts } from '~/hooks/useProducts';

export const CartItemsSummary = () => {
  const { t } = useTranslation();
  const {
    lead: { email, cartItems, webAppUrl },
    selectedPlan,
  } = useAppLead();
  const { findProductPrice } = useProducts();
  const planPrice = findProductPrice(selectedPlan);
  const filteredItems = cartItems?.filter((item) => item.isUpsell && !/upgrade/.test(item.key));
  const upgradeProduct = cartItems?.find((item) => /upgrade/.test(item.key));
  const upgradePrice = findProductPrice(upgradeProduct);

  return (
    <Box width='100%'>
      <Link target='_blank' href={webAppUrl}>
        <Button width='100%' maxWidth='20em' margin='0 auto 2rem auto'>
          {t('success:access_my_plan')}
        </Button>
      </Link>
      <Box
        padding={{ base: '1rem', md: '1.5rem' }}
        backgroundColor='rgba(0, 0, 0, 0.05)'
        borderRadius='0.5rem'
        maxWidth='40rem'
        margin='0 auto 1.5rem auto'
        border='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Flex gap='1rem' alignItems='start' justifyContent='space-between'>
          <Text color='#3f3f3f' textAlign='left' fontSize='14px'>
            {selectedPlan?.title}
          </Text>
          <Text fontWeight={600} color='black' textAlign='right' fontSize='15px'>
            {generalUtils.formatPrice(planPrice.finalPrice, planPrice.currency)}
          </Text>
        </Flex>
        {upgradeProduct ? (
          <Flex gap='1rem' alignItems='start' justifyContent='space-between' marginTop='0.5rem'>
            <Text color='#3f3f3f' textAlign='left' fontSize='14px'>
              Upgrade to annual plan
            </Text>
            <Text fontWeight={600} color='black' textAlign='right' fontSize='15px'>
              {generalUtils.formatPrice(upgradePrice.finalPrice, upgradePrice.currency)}
            </Text>
          </Flex>
        ) : null}
        {filteredItems?.length ? (
          <Text marginTop='1rem' fontWeight={700} marginBottom='0.25rem' color='black'>
            Extensions:
          </Text>
        ) : null}
        {filteredItems?.map((item, index: number) => {
          const priceBase = findProductPrice(item);

          return (
            <Box
              key={index}
              borderBottom={index + 1 === filteredItems.length ? 'none' : '1px solid #e2e2e2'}
              paddingBottom={index + 1 === filteredItems.length ? '0' : '0.75rem'}
              marginBottom={index + 1 === filteredItems.length ? 0 : '0.75rem'}
            >
              <Flex gap='1rem' alignItems='start' justifyContent='space-between'>
                <Text color='#3f3f3f' textAlign='left' fontSize='14px'>
                  {item.title} (6-months access)
                </Text>
                <Text fontWeight={600} color='black' textAlign='right' fontSize='15px'>
                  {generalUtils.formatPrice(priceBase.finalPrice / item.plan.interval, priceBase.currency)}/month
                </Text>
              </Flex>
            </Box>
          );
        })}
      </Box>
      <TransText
        transKey='success:we_will_send_you_an_email'
        maxWidth='40rem'
        margin='0 auto 2rem auto'
        color='rgba(0, 0, 0, 0.8)'
        values={{
          email,
        }}
        components={{
          a: <a style={{ textDecoration: 'underline' }} target='_blank' href={config.supportUrl} />,
        }}
      ></TransText>
    </Box>
  );
};
