import { Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';

import StarsSVG from '~/assets/icons/stars.svg';
import Testimonial1PNG from '~/assets/images/testimonial_1.png';
import Testimonial1MalePNG from '~/assets/images/testimonial_1_male.png';
import Testimonial2PNG from '~/assets/images/testimonial_2.png';
import Testimonial2MalePNG from '~/assets/images/testimonial_2_male.png';
import Testimonial3PNG from '~/assets/images/testimonial_3.png';
import Testimonial3MalePNG from '~/assets/images/testimonial_3_male.png';
import { useAppLead } from '~/hooks/useAppLead';

import { TransText } from '../TransText';

export const TestimonialsList = () => {
  const { gender } = useAppLead();

  return (
    <>
      <Box overflow='hidden' borderRadius='0.5rem' bg='white'>
        <Flex position='relative' width='100%' paddingBottom='80%'>
          <Image
            src={gender === 'male' ? Testimonial1MalePNG : Testimonial1PNG}
            alt=''
            placeholder='blur'
            fill
            objectFit='cover'
          />
          <Box position='absolute' top='0.75rem' left='0.75rem' bg='#F85A69' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:before'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
          <Box position='absolute' top='0.75rem' left='52%' bg='#58B14E' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:after'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
        </Flex>
        <Box padding='1rem'>
          <Flex marginBottom='1rem' gap='0.5rem' justifyContent='space-between' alignItems='center'>
            <Flex>
              <StarsSVG width='100px' height='20px' viewBox='0 0 121 24' />
            </Flex>
            <TransText
              transKey='plans:static_weight_lose'
              fontSize='20px'
              fontWeight={700}
              values={{ weightStatic: 21 }}
              textAlign='right'
            ></TransText>
          </Flex>
          <TransText transKey='plans:reviews:wont_happen_for_me' marginBottom='1rem'></TransText>
          <Text fontWeight={700}>{gender === 'male' ? 'Brian S.' : 'Martha S.'}</Text>
          <Text fontSize='14px'>Atlanta, GA</Text>
        </Box>
      </Box>
      <Box overflow='hidden' borderRadius='0.5rem' bg='white'>
        <Flex position='relative' width='100%' paddingBottom='80%'>
          <Image
            src={gender === 'male' ? Testimonial2MalePNG : Testimonial2PNG}
            alt=''
            placeholder='blur'
            fill
            objectFit='cover'
          />
          <Box position='absolute' top='0.75rem' left='0.75rem' bg='#F85A69' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:before'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
          <Box position='absolute' top='0.75rem' left='52%' bg='#58B14E' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:after'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
        </Flex>
        <Box padding='1rem'>
          <Flex marginBottom='1rem' gap='0.5rem' justifyContent='space-between' alignItems='center'>
            <Flex>
              <StarsSVG width='100px' height='20px' viewBox='0 0 121 24' />
            </Flex>
            <TransText
              transKey='plans:static_weight_lose'
              fontSize='20px'
              fontWeight={700}
              values={{ weightStatic: 12 }}
              textAlign='right'
            ></TransText>
          </Flex>
          <TransText transKey='plans:reviews:eat_and_exercise' marginBottom='1rem'></TransText>
          <Text fontWeight={700}>{gender === 'male' ? 'Tony L.' : 'Caroll R.'}</Text>
          <Text fontSize='14px'>Los Angeles, CA</Text>
        </Box>
      </Box>
      <Box overflow='hidden' borderRadius='0.5rem' bg='white'>
        <Flex position='relative' width='100%' paddingBottom='80%'>
          <Image
            src={gender === 'male' ? Testimonial3MalePNG : Testimonial3PNG}
            alt=''
            placeholder='blur'
            fill
            objectFit='cover'
          />
          <Box position='absolute' top='0.75rem' left='0.75rem' bg='#F85A69' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:before'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
          <Box position='absolute' top='0.75rem' left='52%' bg='#58B14E' padding='6px' borderRadius='0.25rem'>
            <TransText
              transKey='common:after'
              fontSize='12px'
              fontWeight={500}
              color='white'
              lineHeight='100%'
              textTransform='uppercase'
            ></TransText>
          </Box>
        </Flex>
        <Box padding='1rem'>
          <Flex marginBottom='1rem' gap='0.5rem' justifyContent='space-between' alignItems='center'>
            <Flex>
              <StarsSVG width='100px' height='20px' viewBox='0 0 121 24' />
            </Flex>
            <TransText
              transKey='plans:static_weight_lose'
              fontSize='20px'
              fontWeight={700}
              values={{ weightStatic: 43 }}
              textAlign='right'
            ></TransText>
          </Flex>
          <TransText transKey='plans:reviews:experimented_other_programs' marginBottom='1rem'></TransText>
          <Text fontWeight={700}>{gender === 'male' ? 'Kevin O.' : 'Christine B.'}</Text>
          <Text fontSize='14px'>Seattle, WA</Text>
        </Box>
      </Box>
    </>
  );
};
