export const FUNNELS: Record<
  | 'main'
  | 'cortisol'
  | 'pcos'
  | 'inflammation'
  | 'report'
  | 'challenge'
  | 'cortisol-bf'
  | 'generic'
  | 'cortisol-xmas'
  | 'cortisol-ny'
  | 'generic-plan'
  | 'trial',
  | 'main'
  | 'cortisol'
  | 'pcos'
  | 'inflammation'
  | 'report'
  | 'challenge'
  | 'cortisol-bf'
  | 'generic'
  | 'cortisol-xmas'
  | 'cortisol-ny'
  | 'trial'
  | 'generic-plan'
> = {
  main: 'main',
  cortisol: 'cortisol',
  pcos: 'pcos',
  inflammation: 'inflammation',
  report: 'report',
  challenge: 'challenge',
  'cortisol-bf': 'cortisol-bf',
  'cortisol-xmas': 'cortisol-xmas',
  'cortisol-ny': 'cortisol-ny',
  generic: 'generic',
  'generic-plan': 'generic-plan',
  trial: 'trial',
};

export const GENERIC_FUNNELS_LIST = ['generic', 'generic-plan'];
