import { extendTheme } from '@chakra-ui/react';

import { GENERIC_FUNNELS_LIST } from '~/constants';
import { Funnel } from '~/types';

import { buttonTheme } from './button';
import { colors } from './colors';
import { fonts } from './fonts';
import { inputTheme } from './input';
import { textStyles } from './text';

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'body',
      },
      input: {
        fontFamily: fonts,
      },
    },
  },
  fonts,
  colors,
  textStyles,
  radii: {
    button: '0.5rem',
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Text: {
      baseStyle: {
        color: 'heading',
      },
    },
    Container: {
      baseStyle: {
        width: 'auto',
        paddingLeft: '6%',
        paddingRight: '6%',
      },
      variants: {
        lg: {
          maxWidth: '71.25rem', // 1140px
        },
        md: {
          maxWidth: '66rem', // TODO: change this value to other
        },
        sm: {
          maxWidth: '27rem', // TODO: change this value to other
        },
      },
      defaultProps: {
        variant: 'lg',
      },
    },
  },
});

export const getFunnelTheme = (funnel: Funnel) => {
  if (GENERIC_FUNNELS_LIST.includes(funnel)) {
    theme.colors.body = '##16092B';
    theme.colors.heading = '#16092B';
    theme.colors.primary = '#AB8DDB';
    theme.colors.secondary = '#2FA86D';
    theme.colors.buttonSecondary = '#fff';
    theme.colors.questionSubheading = '#2FA86D';
    theme.colors.inputBg = 'white';
    theme.colors.inputBorder = 'white';
    theme.colors.question.option.bg = 'white';
    theme.colors.question.option.border = 'transparent';
    theme.colors.question.option.bgHover = '#faf9f9';
    theme.colors.question.option.bgActive = '#2FA86D';
    theme.colors.question.option.borderActive = '#2FA86D';
    theme.colors.question.option.textActive = '#fff';
    theme.colors.question.option.iconFill = '#fff';
    theme.colors.info.bg = '#F3EBFF';
    theme.colors.info.border = '#AB8DDB';
    theme.colors.toggle.border = '#DAF3DB';
    theme.colors.toggle.bg = '#DAF3DB';
    theme.colors.toggle.bgActive = '#2FA86D';

    theme.radii.button = '2rem';

    theme.textStyles.quizTitle.fontFamily = 'heading2';
  }

  return theme;
};

export { fonts };
