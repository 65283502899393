import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { generalUtils, useHydration } from '@shared/common';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef, useState } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import CheckSVG from '~/assets/icons/check.svg';
import Mobile2XSVG from '~/assets/icons/mobile_2x.svg';
import ThumbUpSVG from '~/assets/icons/thumb_up.svg';
import { ChallengeCompletionDate } from '~/components/ChallengeCompletionDate';
import { TransText } from '~/components/TransText';
import { config } from '~/config';
import { useAnalytics } from '~/hooks/useAnalytics';

import { useAppLead } from '../../hooks/useAppLead';
import { useAppRouter } from '../../hooks/useAppRouter';
import { useProducts } from '../../hooks/useProducts';

const firstPulse = keyframes`
  0% {box-shadow: 0 0 0 0 #3FA134;}
  20% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelectionTags = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { findProductById, findTrialPrice, findProductPrice, initialPlan } = useProducts();
  const { updateUser } = useAppLead();
  const analytics = useAnalytics();
  const isHydrated = useHydration();
  const { pushNextFunnelRoute } = useAppRouter('/checkout');
  const { t } = useTranslation();
  const [activePlan, setActivePlan] = useState(initialPlan['6']);
  const selectedPlanPrice = findProductPrice(findProductById(activePlan));

  const handleSubmit = () => {
    const product = findProductById(activePlan) || findProductById(initialPlan['6']);

    if (product) {
      analytics.log('plans_select', { category: product.name, label: product.key });
      updateUser({ selectedPlan: product });

      pushNextFunnelRoute();
    }
  };

  return isHydrated ? (
    <Flex flexDir='column' gap='0.5rem' {...props} ref={ref}>
      {(() => {
        const product = findProductById(initialPlan['1']);
        const { price, finalPrice, currency } = findTrialPrice(product);
        const priceInDollar = Math.floor(finalPrice / 7);
        const priceInCents = Math.floor((finalPrice / 7 - Math.floor(finalPrice / 7)) * 100);

        return product ? (
          <Box
            padding={{ base: '0.75rem 0.5rem', md: '1rem' }}
            border='2px solid'
            borderColor={activePlan === product.id ? '#5B9255' : '#efefef'}
            borderRadius='0.5rem'
            width='100%'
            backgroundColor={activePlan === product.id ? '#5b925508' : 'system.white'}
            cursor='pointer'
            onClick={() => setActivePlan(product.id)}
          >
            <Flex gap={{ base: '1rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
              <Flex alignItems='center' gap={{ base: '0.5rem', md: '0.75rem' }}>
                {activePlan === product.id ? (
                  <CheckSVG width='20px' height='20px' fill='#5B9255' />
                ) : (
                  <Box width='20px' height='20px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                )}
                <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                  <Box>
                    <Text
                      fontWeight={700}
                      fontSize='14px'
                      marginBottom='0.25rem'
                      lineHeight='100%'
                      textTransform='uppercase'
                    >
                      {t('common:x_day_plan', { interval: 7 })}
                    </Text>
                    <Flex gap='0.25rem' alignItems='center'>
                      <Text fontSize='13px' color='system.error' textDecoration='line-through'>
                        {generalUtils.formatPrice(price, currency)}
                      </Text>
                      <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                        {generalUtils.formatPrice(finalPrice, currency)}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
              <Flex alignItems='end' gap='0.25rem'>
                <Text textDecoration='line-through' fontSize='13px'>
                  {generalUtils.formatPrice(price / 7, currency)}
                </Text>
                <Flex alignItems='center'>
                  <svg
                    fill={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    height='50px'
                    viewBox='0 0 14 42'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                  </svg>
                  <Flex
                    padding='0.5rem'
                    backgroundColor={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    borderRadius='0 0.25rem 0.25rem 0'
                    gap={{ base: '0.125rem', md: '0.25rem' }}
                    alignItems='flex-start'
                  >
                    <Text fontSize='12px'>$</Text>
                    <Text fontSize='30px' lineHeight='100%'>
                      {priceInDollar}
                    </Text>
                    <Box>
                      <Text lineHeight='100%' fontWeight={500} fontSize='14px'>
                        {Math.floor(priceInCents)}
                      </Text>
                      <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                        {t('common:per_day')}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
      {(() => {
        const product = findProductById(initialPlan['6']);
        const { price, finalPrice, currency } = findTrialPrice(product);
        const priceInDollar = Math.floor(finalPrice / 28);
        const priceInCents = Math.floor((finalPrice / 28 - Math.floor(finalPrice / 28)) * 100);

        return product ? (
          <Box
            border='2px solid'
            borderColor={activePlan === product.id ? '#5B9255' : '#efefef'}
            borderRadius='0.5rem'
            width='100%'
            backgroundColor={activePlan === product.id ? '#5b925508' : 'system.white'}
            cursor='pointer'
            onClick={() => setActivePlan(product.id)}
          >
            <Flex
              padding='0.25rem 0.5rem'
              borderRadius='0.25rem 0.25rem 0 0'
              backgroundColor={activePlan === product.id ? '#5B9255' : '#c0c1c7'}
              marginBottom='0.5rem'
              justifyContent='center'
              alignItems='center'
              gap='0.5rem'
            >
              <ThumbUpSVG width='16px' height='16px' viewBox='0 0 48 48' fill='white' />
              <Text
                color='white'
                fontWeight={500}
                fontSize='10px'
                textAlign='center'
                lineHeight='100%'
                textTransform='uppercase'
              >
                MOST POPULAR
              </Text>
            </Flex>
            <Flex
              padding={{ base: '0.75rem 0.5rem', md: '1rem' }}
              gap={{ base: '1rem', md: '1.5rem' }}
              justifyContent='space-between'
              alignItems='center'
            >
              <Flex alignItems='center' gap={{ base: '0.5rem', md: '0.75rem' }}>
                {activePlan === product.id ? (
                  <CheckSVG width='20px' height='20px' fill='#5B9255' />
                ) : (
                  <Box width='20px' height='20px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                )}
                <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                  <Box>
                    <TransText
                      transKey='common:x_months_plan'
                      fontWeight={700}
                      fontSize='14px'
                      marginBottom='0.25rem'
                      lineHeight='100%'
                      color='primaryDark'
                      values={{
                        interval: product.plan.interval,
                        count: product.plan.interval,
                      }}
                      textTransform='uppercase'
                    ></TransText>
                    <Flex gap='0.25rem' alignItems='center '>
                      <Text fontSize='13px' color='system.error' textDecoration='line-through'>
                        {generalUtils.formatPrice(price, currency)}
                      </Text>
                      <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                        {generalUtils.formatPrice(finalPrice, currency)}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
              <Flex alignItems='end' gap='0.25rem'>
                <Text textDecoration='line-through' fontSize='13px'>
                  {generalUtils.formatPrice(price / 28, currency)}
                </Text>
                <Flex alignItems='center'>
                  <svg
                    fill={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    height='50px'
                    viewBox='0 0 14 42'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                  </svg>
                  <Flex
                    padding='0.5rem'
                    backgroundColor={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    borderRadius='0 0.25rem 0.25rem 0'
                    gap={{ base: '0.125rem', md: '0.25rem' }}
                    alignItems='flex-start'
                  >
                    <Text fontSize='12px'>$</Text>
                    <Text fontSize='30px' lineHeight='100%'>
                      {priceInDollar.toFixed(0)}
                    </Text>
                    <Box>
                      <Text lineHeight='100%' fontWeight={500} fontSize='14px'>
                        {Math.floor(priceInCents)}
                      </Text>
                      <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                        {t('common:per_day')}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
      {(() => {
        const product = findProductById(initialPlan['3']);
        const { price, finalPrice, currency } = findTrialPrice(product);
        const priceInDollar = Math.floor(finalPrice / 84);
        const priceInCents = Math.floor((finalPrice / 84 - Math.floor(finalPrice / 84)) * 100);

        return product ? (
          <Box
            padding={{ base: '0.75rem 0.5rem', md: '1rem' }}
            border='2px solid'
            borderColor={activePlan === product.id ? '#5B9255' : '#efefef'}
            backgroundColor={activePlan === product.id ? '#5b925508' : 'system.white'}
            borderRadius='0.5rem'
            width='100%'
            cursor='pointer'
            onClick={() => setActivePlan(product.id)}
          >
            <Flex gap={{ base: '0rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
              <Flex alignItems='center' gap={{ base: '0.5rem', md: '0.75rem' }}>
                {activePlan === product.id ? (
                  <CheckSVG width='20px' height='20px' fill='#5B9255' />
                ) : (
                  <Box width='20px' height='20px' border='2px solid #D1D3CD' borderRadius='1.5rem' />
                )}
                <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                  <Box>
                    <TransText
                      transKey='common:x_months_plan'
                      fontWeight={700}
                      fontSize='14px'
                      marginBottom='0.25rem'
                      lineHeight='100%'
                      color='primaryDark'
                      values={{
                        interval: product.plan.interval,
                        count: product.plan.interval,
                      }}
                      textTransform='uppercase'
                    ></TransText>
                    <Flex gap='0.25rem' alignItems='center'>
                      <Text fontSize='13px' color='system.error' textDecoration='line-through'>
                        {generalUtils.formatPrice(price, currency)}
                      </Text>
                      <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                        {generalUtils.formatPrice(finalPrice, currency)}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
              <Flex alignItems='end' gap='0.25rem'>
                <Text textDecoration='line-through' fontSize='13px'>
                  {generalUtils.formatPrice(price / 84, currency)}
                </Text>
                <Flex alignItems='center'>
                  <svg
                    fill={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    height='50px'
                    viewBox='0 0 14 42'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                  </svg>
                  <Flex
                    padding='0.5rem'
                    backgroundColor={activePlan === product.id ? '#dadeff' : '#e9eaf2'}
                    borderRadius='0 0.25rem 0.25rem 0'
                    gap={{ base: '0.125rem', md: '0.25rem' }}
                    alignItems='flex-start'
                  >
                    <Text fontSize='12px'>$</Text>
                    <Text fontSize='30px' lineHeight='100%'>
                      {priceInDollar}
                    </Text>
                    <Box>
                      <Text lineHeight='100%' fontWeight={500} fontSize='14px'>
                        {Math.floor(priceInCents)}
                      </Text>
                      <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                        {t('common:per_day')}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
      <Flex alignItems='center' gap='0.75rem' marginTop='0.5rem'>
        <Flex>
          <Mobile2XSVG />
        </Flex>
        <Text flex={1} fontSize='13px'>
          People using Degisti for 3 months lose twice as much weight as for 1 month
        </Text>
      </Flex>
      <Button
        width='100%'
        animation={`10s ease 0s infinite normal none running ${firstPulse}`}
        gap='1rem'
        marginBottom='0.5rem'
        marginTop='0.5rem'
        onClick={() => handleSubmit()}
      >
        <Text color='white'>{t('common:claim_my_plan')}</Text>
        <ArrowSVG fill='white' />
      </Button>
      <ChallengeCompletionDate />
      <Text fontSize='11px' color='rgba(0, 0, 0, 0.6)' textAlign='center'>
        {t('checkout:trial_disclaimer_copy', {
          value: generalUtils.formatPrice(selectedPlanPrice.finalPrice, selectedPlanPrice.currency),
          interval: findProductById(activePlan)?.plan?.interval,
          projectName: config.name,
        })}
      </Text>
    </Flex>
  ) : null;
});

PlanSelectionTags.displayName = 'PlanSelectionTags';
