import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import Age1833Image from '~/assets/images/age_18_33.png';
import Age3344Image from '~/assets/images/age_33_44.png';
import Age4455Image from '~/assets/images/age_44_55.png';
import Age55Image from '~/assets/images/age_55.png';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useTracking } from '~/hooks/useTracking';
import { Funnel } from '~/types';

import { useAppLead } from '../hooks/useAppLead';

export const AgeSelection: FC<{ funnelName?: Funnel }> = ({ funnelName }) => {
  const { updateQuizAnswers } = useAppLead();
  const tracking = useTracking();
  const { getNextFunnelUrl } = useAppRouter('/quiz');

  const handleSelection = (ageRange: '20' | '30' | '40' | '50') => {
    updateQuizAnswers({ ageRange });

    tracking.quizQuestionAnswer({ category: '01_age_range', label: ageRange });
  };

  return (
    <Grid
      gap={{ base: '0.5rem', md: '1rem' }}
      width='100%'
      gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
    >
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)} onClick={() => handleSelection('20')}>
        <Box borderRadius='0.5rem' overflow='hidden' backgroundColor='#F2EAFF' cursor='pointer'>
          <Flex position='relative' paddingBottom='80%' overflow='hidden'>
            <Image src={Age1833Image} fill alt='' style={{ objectFit: 'contain' }} />
          </Flex>
          <Flex
            backgroundColor='#AB8DDB'
            padding='0.75rem 1rem'
            gap='1.25rem'
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontWeight={500} fontSize='18px' color='white'>
              Age: 18-33
            </Text>
            <ArrowSVG fill='white' />
          </Flex>
        </Box>
      </Link>
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)} onClick={() => handleSelection('30')}>
        <Box borderRadius='0.5rem' overflow='hidden' backgroundColor='#F2EAFF' cursor='pointer'>
          <Flex position='relative' paddingBottom='80%' overflow='hidden'>
            <Image src={Age3344Image} fill alt='' style={{ objectFit: 'contain' }} />
          </Flex>
          <Flex
            backgroundColor='#AB8DDB'
            padding='0.75rem 1rem'
            gap='1.25rem'
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontWeight={500} fontSize='18px' color='white'>
              Age: 33-44
            </Text>
            <ArrowSVG fill='white' />
          </Flex>
        </Box>
      </Link>
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)} onClick={() => handleSelection('40')}>
        <Box borderRadius='0.5rem' overflow='hidden' backgroundColor='#F2EAFF' cursor='pointer'>
          <Flex position='relative' paddingBottom='80%' overflow='hidden'>
            <Image src={Age4455Image} fill alt='' style={{ objectFit: 'contain' }} />
          </Flex>
          <Flex
            backgroundColor='#AB8DDB'
            padding='0.75rem 1rem'
            gap='1.25rem'
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontWeight={500} fontSize='18px' color='white'>
              Age: 44-55
            </Text>
            <ArrowSVG fill='white' />
          </Flex>
        </Box>
      </Link>
      <Link href={getNextFunnelUrl(funnelName ? { funnelName } : undefined)} onClick={() => handleSelection('50')}>
        <Box borderRadius='0.5rem' overflow='hidden' backgroundColor='#F2EAFF' cursor='pointer'>
          <Flex position='relative' paddingBottom='80%' overflow='hidden'>
            <Image src={Age55Image} fill alt='' style={{ objectFit: 'contain' }} />
          </Flex>
          <Flex
            backgroundColor='#AB8DDB'
            padding='0.75rem 1rem'
            gap='1.25rem'
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontWeight={500} fontSize='18px' color='white'>
              Age: 55+
            </Text>
            <ArrowSVG fill='white' />
          </Flex>
        </Box>
      </Link>
    </Grid>
  );
};
