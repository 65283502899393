export const inputTheme = {
  baseStyle: {
    field: {},
  },
  variants: {
    default: {
      field: {
        border: '1px solid',
        borderColor: 'inputBorder',
        background: 'inputBg',
        padding: '1.5rem 1rem',
        fontSize: '16px',
        fontFamily: 'body',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
