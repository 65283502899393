import { defineStyleConfig } from '@chakra-ui/react';

import { fonts } from './fonts';

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: fonts.body,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 'button',
    border: 'none',
    textAlign: 'center',
    color: 'heading',
    fontWeight: 500,
    transition: 'all 200ms',

    _hover: {
      cursor: 'pointer',
    },
  },
  sizes: {
    big: {
      padding: '1rem 1.5rem',
      fontSize: '16px',
    },
    small: {
      padding: '0.75rem 1.5rem',
      borderRadius: '0.75rem',
    },
  },
  variants: {
    primary: {
      background: 'primary',
      color: 'buttonPrimary',
      border: 'none',

      _hover: {
        backgroundColor: 'primary',

        _disabled: {
          background: 'primary',
        },
      },

      _disabled: {
        background: 'primary',
        opacity: 1,
      },
    },
    secondary: {
      background: 'secondary',
      color: 'buttonSecondary',

      _hover: {
        backgroundColor: 'secondary',
      },
    },
    yellow: {
      background: '#FFD442',
      color: '#233E20',
      border: 'none',

      _hover: {
        backgroundColor: '#FFD442',
      },
    },
    blank: {
      background: 'none',
    },
    green: {
      background: '#3FA134',
      color: 'white',
    },
  },
  defaultProps: {
    size: 'big',
    variant: 'primary',
  },
});
