import { Input, Box, Button, Text, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { generalUtils } from '@shared/common';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import MailCheck from 'react-mailcheck';
import * as yup from 'yup';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { TransText } from '~/components/TransText';
import { useCookies } from '~/hooks/useCookies';
import { useLocale } from '~/hooks/useLocale';

import { useAppLead } from '../hooks/useAppLead';
import { useAppRouter } from '../hooks/useAppRouter';
import { useLeadMutation } from '../hooks/useLeadMutation';

export const EmailForm: FC<{
  buttonCopy?: string;
  preventDefault?: boolean;
  children?: ReactNode;
  onSubmit?: () => void;
}> = ({ children, buttonCopy, preventDefault, onSubmit }) => {
  const { t } = useTranslation();
  const { lang } = useLocale();
  const { pushNextFunnelRoute, funnelName } = useAppRouter('/intro');
  const cookies = useCookies();
  const { id, lead, quizAnswers } = useAppLead();
  const leadMutation = useLeadMutation({
    onSuccess: () => {
      if (!preventDefault) {
        pushNextFunnelRoute(funnelName === 'main' ? { funnelName: 'cortisol' } : undefined);
      } else if (onSubmit) {
        onSubmit();
      }
    },
    onError: () => {
      setError('email', { message: t('common:something_went_wrong_try_again') });
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('The email must be a valid email address')
          .required('Email field is required to fill'),
      }),
    ),
  });

  const handleFormSubmit = (email?: string) => {
    leadMutation.mutate({
      id,
      email,
      quizAnswers,
      country: cookies.country,
      ip: cookies.ip,
      city: cookies.city,
      region: cookies.region,
      zip: cookies.zip,
      customFields: {
        ...generalUtils.getTrackingData(),
        flowName: funnelName,
        lang,
      },
    });
  };

  return (
    <MailCheck email={watch('email') || ''}>
      {(suggestion: { full: string }) => (
        <Box as='form' onSubmit={handleSubmit((formData) => handleFormSubmit(formData.email))} width='100%'>
          <Box marginBottom={{ base: '0.5rem', md: '1rem' }}>
            <Flex alignItems='center' position='relative'>
              <Input
                placeholder={t('common:your_email_address')}
                type='email'
                required
                borderRadius='0.5rem'
                fontSize='16px'
                padding='1.75rem 1.25rem'
                outline='none'
                boxShadow='none'
                backgroundColor='system.white'
                defaultValue={lead?.email || ''}
                {...register('email')}
              />
              <Flex position='absolute' right='12px' top='50%' transform='translateY(-50%)' zIndex={2}>
                <LockSVG />
              </Flex>
            </Flex>

            {suggestion?.full && (
              <TransText
                transKey='common:did_you_mean'
                fontSize='14px'
                textStyle='small'
                fontWeight={500}
                color='#9d9d9d'
                marginTop='0.5rem'
                cursor='pointer'
                onClick={() => setValue('email', suggestion.full)}
                values={{ value: suggestion.full }}
              ></TransText>
            )}
            {errors?.email?.message || leadMutation.error ? (
              <Text fontSize='14px' fontWeight={500} color='system.error' marginTop='0.5rem'>
                {(errors?.email?.message as string) || leadMutation.error}
              </Text>
            ) : null}
            {children}
          </Box>
          <Box
            position={{ base: preventDefault ? 'static' : 'fixed', md: 'static' }}
            bottom='0'
            left='0'
            right='0'
            padding={{ base: preventDefault ? '0' : '1rem 6% 3rem 6%', md: '0' }}
            backgroundColor={{ base: 'rgba(255, 255,255, 0.08)', md: 'transparent' }}
            borderTop={{ base: '1px solid rgba(255, 255, 255, 0.16)', md: 'none' }}
            backdropBlur='blur(20px)'
            zIndex={2}
          >
            <Button
              as='button'
              gap='1rem'
              isLoading={leadMutation.status === 'loading'}
              width='100%'
              disabled={!watch('email')}
              type='submit'
            >
              {buttonCopy ? buttonCopy : t('email:unclock_your_results')}
              <ArrowSVG fill='#fff' />
            </Button>
          </Box>
        </Box>
      )}
    </MailCheck>
  );
};

EmailForm.displayName = 'EmailForm';

const LockSVG = () => (
  <svg height='24' viewBox='0 0 48 48' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0h48v48H0z' fill='none' />
    <path
      fill='#828282'
      d='M36 16h-2v-4c0-5.52-4.48-10-10-10S14 6.48 14 12v4h-2c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V20c0-2.21-1.79-4-4-4zM24 34c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.2-18H17.8v-4c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4z'
    />
  </svg>
);
