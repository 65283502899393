export const textStyles = {
  h1: {
    fontSize: ['28px', '36px'],
    lineHeight: '110%',
    fontWeight: 700,
    fontFamily: 'heading',
  },
  h2: {
    fontSize: ['32px', '32px'],
    fontWeight: 900,
    lineHeight: '132%',
    letterSpacing: '4%',
  },
  h3: {
    fontSize: ['24px', '24px'],
    fontWeight: 700,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '132%',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '140%',
  },
  body: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
  caption: {
    fontSize: '18px',
    fontWeight: 400,
  },
  small: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
  quizTitle: {
    fontSize: ['28px', '36px'],
    lineHeight: '110%',
    fontWeight: 700,
    fontFamily: 'heading',
    color: 'heading',
  },
};
