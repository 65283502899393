import { Inter, Lexend } from 'next/font/google';
import localFont from 'next/font/local';

const InterFont = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-body',
});

const LexendFont = Lexend({
  weight: ['600'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-heading2',
});

const CabinetGrotskFont = localFont({
  src: [
    {
      path: './../assets/fonts/CabinetGrotesk-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../assets/fonts/CabinetGrotesk-Bold.woff',
      weight: '700',
      style: 'bold',
    },
    {
      path: './../assets/fonts/CabinetGrotesk-Extrabold.woff',
      weight: '900',
      style: 'extrabold',
    },
  ],
  display: 'swap',
  variable: '--cabinetGroteskFont',
});

export const fonts = {
  body: InterFont.style.fontFamily,
  heading: CabinetGrotskFont.style.fontFamily,
  heading2: LexendFont.style.fontFamily,
};
