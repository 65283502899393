import { useState } from 'react';

import { config } from '~/config';

import { useAppLead } from './useAppLead';
import { FirebaseFunction, MutationOptions, useFirebaseMutation } from './useFirebase';
import { useTracking } from './useTracking';

interface LeadData {
  id?: string;
  email?: string;
  quizAnswers?: Record<string, unknown>;
  country?: string | null;
  region?: string | null;
  zip?: string | null;
  ip?: string | null;
  city?: string | null;
  customFields?: Record<string, unknown>;
}

type LeadResponse =
  | {
      id: string;
      email: string;
    }
  | { status: 'error'; message: string };

export const useLeadMutation = (options?: MutationOptions<LeadResponse, LeadData>) => {
  const { updateUser } = useAppLead();
  const tracking = useTracking();
  const [error, setError] = useState('');

  const leadMutation = useFirebaseMutation<LeadResponse, LeadData>(FirebaseFunction.CREATE_LEAD, {
    ...options,
    onSuccess: (response, variables, context) => {
      const data = response.data;

      if ('status' in data && data.status === 'error') {
        setError(data.message);
      } else if ('id' in data) {
        const { id, email } = data;

        if (!variables.id) {
          tracking.emailLeft({ leadId: id, email: variables.email });
        }

        updateUser({ id, email, webAppUrl: `${config.webAppUrl}?leadCode=${id}` });

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      }
    },
  });

  return { ...leadMutation, error };
};
