import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { memo, useState } from 'react';
import { PaymentProvider, PaymentsConfig } from '../types/general';

const PP_CLIENT = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string;

const PP_LOADING_BG = '#ffd269';
const PP_SPINNER_COLOR = '#0E3282';

export const Paypal = memo(
  ({ options, forcePPRerender, createOrder, onSuccess, onSubmit, onCancel, onError }: PaymentsConfig) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const createMethod = options?.isSubscription
      ? {
          createSubscription: async (_: any, actions: unknown) => {
            setIsLoading(true);

            if (onSubmit) {
              onSubmit({ paymentProvider: PaymentProvider.PAYPAL });
            }

            const planConfig = (await createOrder({ paymentProvider: PaymentProvider.PAYPAL })) as {
              id?: string;
              plan_id: string;
              custom_id?: string;
            };

            // @ts-ignore
            return actions.subscription.create({
              plan_id: planConfig.plan_id || planConfig.id,
              custom_id: planConfig.custom_id,
            });
          },
        }
      : {
          createOrder: async (): Promise<string> => {
            setIsLoading(true);
            const order = await createOrder({ paymentProvider: PaymentProvider.PAYPAL });

            return order.id as string;
          },
        };

    return PP_CLIENT ? (
      <PayPalScriptProvider
        options={{
          'client-id': PP_CLIENT,
          vault: options?.isSubscription ? true : false,
          currency: options?.currency,
        }}
      >
        <Box>
          {error && (
            <Text color='#e12a2a' fontSize='14px' marginBottom='0.5rem'>
              {error}
            </Text>
          )}
          <Box position='relative' width='100%'>
            <PayPalButtons
              {...createMethod}
              forceReRender={forcePPRerender}
              onApprove={async (data, actions) => {
                try {
                  if (data.orderID && !data.subscriptionID) {
                    await actions.order?.capture();
                  }

                  await onSuccess({
                    id: data.subscriptionID || data.orderID,
                    paymentProvider: PaymentProvider.PAYPAL,
                    method: PaymentProvider.PAYPAL,
                  });
                } catch (err) {
                  setError('Oops, something went wrong! Please try again or use another payment method instead.');
                }
                setIsLoading(false);
              }}
              onCancel={() => {
                setIsLoading(false);

                if (onCancel) {
                  onCancel({ paymentProvider: PaymentProvider.PAYPAL });
                }
              }}
              onError={() => {
                setError('Oops, something went wrong! Please try again or use another payment method instead.');
                setIsLoading(false);

                if (onError) {
                  onError({ code: 'unknown' });
                }
              }}
              style={{ layout: 'horizontal', tagline: false }}
            />
            {isLoading && (
              <Flex
                position='absolute'
                backgroundColor={PP_LOADING_BG}
                top='0'
                right='0'
                left='0'
                bottom='0'
                zIndex='9999'
                alignItems='center'
                justifyContent='center'
                borderRadius='4px'
              >
                <Spinner size='sm' color={PP_SPINNER_COLOR} />
              </Flex>
            )}
          </Box>
        </Box>
      </PayPalScriptProvider>
    ) : null;
  },
);

Paypal.displayName = 'Paypal';
