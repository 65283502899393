import { Box, Flex, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { TimeBanner } from '~/layouts/TimeBanner';

export const CouponCodeForm = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { quizAnswers } = useAppLead();
  const name = (quizAnswers?.name as string) || 'today';
  const calendar = quizAnswers?.calendar;
  const date = calendar && typeof calendar === 'string' && isValid(calendar) ? new Date(calendar) : new Date();

  return (
    <Box position='relative' bg='#DFF9ED' borderRadius='0.5rem' padding='1rem' {...props} ref={ref}>
      <Flex
        gap='0.5rem'
        alignItems='center'
        justifyContent='center'
        marginBottom='0.5rem'
        paddingBottom='1rem'
        borderBottom='1px solid #2FA86D66'
      >
        <TicketSVG />
        <Text textAlign='center' fontWeight={700} color='black'>
          Your promo code applied!
        </Text>
      </Flex>
      <Flex alignItems='center' gap='0.5rem'>
        <Text bg='white' padding='0.5rem 1rem' borderRadius='0.25rem' flex={1}>
          {[name.toLowerCase().replaceAll(' ', '_'), format(date, 'LLL'), format(date, 'd')].join('_').toLowerCase()}
        </Text>
        <TimeBanner width='60px' textAlign='center' bg='#2FA86D' borderRadius='0.25rem' color='white' fontSize='16px' />
      </Flex>
      <Box
        position='absolute'
        top='50%'
        transform='translateY(-50%)'
        left='-0.5rem'
        width='1rem'
        height='1rem'
        borderRadius='1rem'
        bg='white'
      />
      <Box
        position='absolute'
        top='50%'
        transform='translateY(-50%)'
        right='-0.5rem'
        width='1rem'
        height='1rem'
        borderRadius='1rem'
        bg='white'
      />
    </Box>
  );
});

CouponCodeForm.displayName = 'CouponCodeForm';

const TicketSVG = () => (
  <>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.0078 20.9997C11.0078 20.1712 10.3362 19.4997 9.50781 19.4997C8.67939 19.4997 8.00781 20.1712 8.00781 20.9997H3.00781C2.45553 20.9997 2.00781 20.5519 2.00781 19.9997V3.99963C2.00781 3.44735 2.45553 2.99963 3.00781 2.99963H8.00781C8.00781 3.82806 8.67939 4.49963 9.50781 4.49963C10.3362 4.49963 11.0078 3.82806 11.0078 2.99963H21.0078C21.5601 2.99963 22.0078 3.44735 22.0078 3.99963V9.49963C20.6271 9.49963 19.5078 10.619 19.5078 11.9997C19.5078 13.3804 20.6271 14.4997 22.0078 14.4997V19.9997C22.0078 20.5519 21.5601 20.9997 21.0078 20.9997H11.0078ZM9.50781 10.4997C10.3362 10.4997 11.0078 9.82806 11.0078 8.99963C11.0078 8.17121 10.3362 7.49963 9.50781 7.49963C8.67939 7.49963 8.00781 8.17121 8.00781 8.99963C8.00781 9.82806 8.67939 10.4997 9.50781 10.4997ZM9.50781 16.4997C10.3362 16.4997 11.0078 15.8281 11.0078 14.9997C11.0078 14.1712 10.3362 13.4997 9.50781 13.4997C8.67939 13.4997 8.00781 14.1712 8.00781 14.9997C8.00781 15.8281 8.67939 16.4997 9.50781 16.4997Z'
        fill='#2FA86D'
      />
    </svg>
  </>
);
